import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import RectangularSearchTextField from "components/RectangularSearchTextField";
import { getTopicsFilterData } from "dashboard/filters/TopicsFilter";
import BorderedOutLineButton from "components/BorderOutLinedButton";
import DropDownWithSearch from "components/DropDownWithSearch";
import MultiSelectDropDownWithInlineSearch from "components/MultiSelectDropDownWithInlineSearch";
import { fecthSubTopicsFilter } from "dashboard/filters/SubTopicsFilter";
const FilterComponent = ({
  topicsSubtopics,
  setSearchTrgiggered,
  searchTriggered,
  componentList,
  setFilteredComponentList,
  dropDownData,
  setDropDownData,
  subTopicsData,
  setSearchInput,
  searchInput,
  subType,
  classification,
  setCurrentPage,
  setHasMore,
}) => {
  const { t } = useTranslation();
  const [isResetClicked, setIsResetClicked] = useState(false);
  const charLimit = 250;
  useEffect(() => {
    if (
      dropDownData?.classification ||
      dropDownData?.searchText ||
      dropDownData?.topic ||
      dropDownData?.subtopics ||
      dropDownData?.subType ||
      dropDownData?.rightsManaged?.id === "yes"
    ) {
      setSearchTrgiggered(true);
    } else {
      setSearchTrgiggered(false);
      setHasMore(true);
    }
  }, [dropDownData]);

  useEffect(() => {
    setDropDownData((prevState) => ({
      ...prevState,
      rightsManaged: {
        id: "no",
        displayName: "No",
      },
    }));
  }, []);

  const handleSearch = (type) => {
    if (type === "search") {
      setDropDownData((prevState) => {
        return { ...prevState, searchText: searchInput?.trim() };
      });
    } else {
      setDropDownData((prevState) => {
        return { ...prevState, searchText: undefined };
      });
    }
    setCurrentPage(1);
  };
  const resetAll = () => {
    setIsResetClicked(true);

    setDropDownData({
      rightsManaged: {
        id: "no",
        displayName: "No",
      },
    });

    setSearchInput("");
    setSearchTrgiggered(false);
    setHasMore(true);
    setCurrentPage(1);
    setFilteredComponentList([]);
    setTimeout(() => {
      setIsResetClicked(false);
    }, [0]);
  };
  const handleDropdownSelection = (value, type) => {
    setCurrentPage(1);
    switch (type) {
      case "topic":
        setDropDownData((prevState) => {
          return { ...prevState, topic: value, subtopics: undefined };
        });
        break;
      case "subtopics":
        setDropDownData((prevState) => {
          return { ...prevState, subtopics: value };
        });
        break;
      case "subType":
        setDropDownData((prevState) => {
          return { ...prevState, subType: value, classification: undefined };
        });
        break;
      case "classification":
        setDropDownData((prevState) => {
          return { ...prevState, classification: value };
        });
        break;
      case "rightsManaged":
        setDropDownData((prevState) => ({
          ...prevState,
          rightsManaged: value,
        }));
        break;
      default:
        break;
    }
  };

  const getSubTypesFilterData = () => {
    return subType
      .filter((item) => item?.subtype !== "")
      .map((ele) => {
        return {
          id: ele.value,
          displayName: ele.label,
        };
      });
  };
  const getClassification = () => {
    if (classification) {
      return classification
        .filter((item) => item !== "")
        .map((ele, index) => {
          return { id: index, displayName: ele };
        });
    }
    return [];
  };

  const getRightsManagedOptions = () => [
    { id: "no", displayName: "No" },
    { id: "yes", displayName: "Yes" },
  ];

  return (
    <>
      <div className="d-flex">
        <div style={{ flex: "0 0 25%", paddingRight: "16px" }}>
          <DropDownWithSearch
            options={{
              value: dropDownData?.subType,
              items: getSubTypesFilterData(subType),
              name: t("metadata.subType"),
              label: t("metadata.subType"),
              isDataTypeObject: true,
              placeholder: "Search and Select",
              handleChange: (selectedValue) => {
                handleDropdownSelection(selectedValue, "subType");
              },
            }}
          />
        </div>
        <div style={{ flex: "0 0 25%", paddingRight: "16px" }}>
          <DropDownWithSearch
            options={{
              value: dropDownData?.classification,
              items: getClassification(),
              name: t("metadata.classification"),
              label: t("metadata.classification"),
              isDataTypeObject: true,
              placeholder: "Search and Select",
              handleChange: (selectedValue) => {
                handleDropdownSelection(selectedValue, "classification");
              },
              disabled: !dropDownData?.subType,
            }}
          />
        </div>
        <div style={{ flex: "0 0 25%", paddingRight: "16px" }}>
          <DropDownWithSearch
            options={{
              value: dropDownData?.topic,
              items: getTopicsFilterData(topicsSubtopics),
              name: t("metadata.topic"),
              label: t("metadata.topic"),
              isDataTypeObject: true,
              placeholder: "Search and Select",
              handleChange: (selectedValue) => {
                handleDropdownSelection(selectedValue, "topic");
              },
            }}
          />
        </div>
        <div style={{ flex: "0 0 25%" }}>
          <MultiSelectDropDownWithInlineSearch
            options={{
              value: dropDownData?.subtopics,
              items: fecthSubTopicsFilter(subTopicsData),
              name: t("metadata.subtopic"),
              label: t("metadata.subtopic"),
              isDataTypeObject: true,
              placeholder: "Search and Select",
              handleChange: (selectedValue) => {
                handleDropdownSelection(selectedValue, "subtopics");
              },
              disabled: !dropDownData?.topic,
            }}
          />
        </div>
      </div>
      <div className="d-flex">
        <div style={{ flex: "0 0 30%", paddingRight: "16px" }}>
          <DropDownWithSearch
            options={{
              value: dropDownData?.rightsManaged,
              items: getRightsManagedOptions(),
              name: "Rights Managed",
              label: "Rights Managed",
              isDataTypeObject: true,
              placeholder: "Select Yes or No",
              handleChange: (selectedValue) => {
                handleDropdownSelection(selectedValue, "rightsManaged");
              },
            }}
          />
        </div>
        <div style={{ flex: "0 0 60%", paddingRight: "16px" }}>
          <RectangularSearchTextField
            placeholder="Search Components"
            onSearchInputChange={(value) => {
              if (
                !value?.length > 0 &&
                (value.trim()?.length === 0 || value.trim() === "")
              ) {
                setSearchInput(undefined);
                handleSearch("searchBarEmpty");
              } else {
                setSearchInput(value);
              }
            }}
            handleSearch={handleSearch}
            isResetClicked={isResetClicked}
            charLimit={charLimit}
          />
        </div>
        <div style={{ flex: "0 0 10%" }}>
          <BorderedOutLineButton
            btnLable="Reset All"
            disabled={!searchTriggered}
            handleClick={resetAll}
          />
        </div>
      </div>
    </>
  );
};
export default FilterComponent;
