import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "styles/modules/importClaims.module.scss";
import PrimaryButton from "components/PrimaryButton";
import PrimaryButtonOutline from "components/PrimaryButtonOutline";
import LinkButton from "components/LinkButton";
import CustomModal from "components/customModal";
import ImportClaims from "components/modules/importclaims/ImportClaims";
import ImportReUsableText from "components/modules/importReusableTexts/ImportResusableText";

import LeftRightScrollButton from "components/LeftRightScrollButton";

import addIcon from "img/addIconSm.svg";

import {
  getElementNameKey,
  getElementsType,
  moduleScreens,
} from "pages/module/ModuleUtil";
import { ModuleContext, moduleActionTypes } from "contexts/ModuleContext";
import { useTranslation } from "react-i18next";
import {
  fetchAddedModuleElements,
  removeElementFromModule,
} from "services/apis";
import { showError } from "Uitls/Util";
import CircularProgressBar from "Uitls/CircularProgressBar";
import ModuleTextAttributes from "./ModuleTextAttributes";

function TextualElements({ elementLabel, elementType }) {
  const [importModalOpen, setImportModalOpen] = useState(false);
  const {
    module,
    updateModule,
    activeScreen,
    setActiveScreen,
    activeElementId,
    currentActiveElementIndex,
    setCurrentElementActiveIndex,
    isloading,
    setIsloading,
    setActiveElementId,
  } = useContext(ModuleContext);
  const { t } = useTranslation();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const closeModal = () => {
    setImportModalOpen(false);
  };
  const closeImportModalAndGoBack = () => {
    closeModal();
    //setActiveScreen(moduleScreens.home);
  };

  useEffect(() => {
    if (isDataLoaded) {
      goToDefaultSlide();
    }
  }, [isDataLoaded]);

  const goToDefaultSlide = () => {
    const data = module[elementType];
    const index = data?.findIndex((el) => {
      return el[getElementNameKey(activeScreen)] === activeElementId;
    });
    if (index > -1) {
      setCurrentElementActiveIndex(index);
    } else if (data?.length > 0) {
      if (currentActiveElementIndex === -1) {
        setCurrentElementActiveIndex(0);
      }
    }
    //setActiveElementId(undefined);
  };

  useEffect(() => {
    if (activeElementId) {
      getAddedTextualElements(true);
    } else {
      //getAddedTextualElements(false);
      goToDefaultSlide();
      //setImportModalOpen(true);
    }
  }, [activeElementId]);

  const getAddedTextualElements = (toView) => {
    const moduleId = module.moduleId;
    setIsloading(true);
    fetchAddedModuleElements(moduleId, elementType, (res, err) => {
      setIsloading(false);
      if (res) {
        updateModule(
          activeScreen === moduleScreens.claims
            ? moduleActionTypes.REPLACE_CLAIMS
            : moduleActionTypes.REPLACE_REUSABLE_TEXTS,
          res.data[elementType]
        );
        setIsDataLoaded(true);
        // if (toView) {
        //   goToDefaultSlide();
        // } else {
        //   if (res.data[elementType]?.length > 0) {
        //     setCurrentElementActiveIndex((prevIndex) => {
        //       if (prevIndex === -1) {
        //         return 0;
        //       }
        //       return module[elementType]?.length;
        //     });
        //   }
        // }
        return;
      }
      showError(err);
    });
  };

  const goBack = () => {
    setCurrentElementActiveIndex(-1);
    setActiveScreen(moduleScreens.home);
    setActiveElementId(undefined);
  };

  return (
    <div className={`${styles.elementsBlockContainer}`}>
      <CircularProgressBar isloading={isloading} />
      <ModuleTextAttributes
        elementLabel={elementLabel}
        elementType={elementType}
      />

      <div
        className={`row mt-20 align-items-center ${styles.moduleFooterRow1}`}
      >
        <div className="col-md-6">
          <PrimaryButtonOutline
            title={`Add ${elementLabel}(s)`}
            handleClick={() => {
              setImportModalOpen(true);
            }}
            minWidth={168}
            icon={addIcon}
            btnType="outline"
            showIcon={true}
          />
        </div>
        <div className="col-md-6 text-right">
          <LeftRightScrollButton
            btnLabel={`${elementLabel}s`}
            handleClick={(direction) => {
              setCurrentElementActiveIndex((prevIndex) => {
                if (direction === "forward") {
                  prevIndex += 1;
                } else {
                  prevIndex -= 1;
                }
                return prevIndex;
              });
            }}
            shouldDisableForward={
              !module ||
              module[elementType]?.length === 0 ||
              currentActiveElementIndex === module[elementType]?.length - 1
            }
            shouldDisableBackward={
              !module ||
              module[elementType]?.length === 0 ||
              currentActiveElementIndex === 0
            }
            currentIndex={currentActiveElementIndex + 1}
            count={module ? module[elementType]?.length : 0}
            goToSlide={(value) => {
              setCurrentElementActiveIndex(value);
            }}
          />
        </div>
      </div>

      <div className={`row align-items-center ${styles.moduleFooterRow}`}>
        <div className="col-md-6">
          <LinkButton
            title="BACK"
            handleClick={() => {
              goBack();
            }}
            // disabled={!selectedData || selectedData.length === 0}
          />
        </div>
        <div className="col-md-6 text-right">
          <PrimaryButton
            title="DONE"
            handleClick={() => {
              goBack();
            }}
            minWidth={110}
            marginLeft={20}
            disabled={!module[getElementsType(activeScreen)]?.length > 0}
          />
        </div>
      </div>
      <CustomModal
        displayModal={importModalOpen && activeScreen === moduleScreens.claims}
        hideModal={() => {
          closeImportModalAndGoBack();
        }}
        size="xl"
        dilogClassName="alertModalSmall"
      >
        <ImportClaims
          closeModalAndRefresh={(recentlyImportedId) => {
            closeModal();
            setIsDataLoaded(() => {
              //getAddedComponents(false, recentlyImportedId);
              setActiveElementId(recentlyImportedId);
              return false;
            });
            //getAddedTextualElements();
          }}
          closeModalAndGoBack={closeImportModalAndGoBack}
          addedClaimIds={module.claims?.map((claim) => {
            return claim.claimName;
          })}
          addedClaimsData={module.claims
            ?.filter((component) => component.country === module.countryName)
            ?.map((claim) => {
              return {
                claimId: claim.claimName,
                topic: claim?.topicName,
                subtopic: claim?.subtopicName,
                description: claim?.matchText,
                messageType: claim?.messageType,
              };
            })}
        />
      </CustomModal>
      <CustomModal
        displayModal={
          importModalOpen && activeScreen === moduleScreens.reusableTexts
        }
        hideModal={() => {
          closeImportModalAndGoBack();
        }}
        size="xl"
        dilogClassName="alertModalSmall"
      >
        <ImportReUsableText
          closeModalAndRefresh={(recentlyImportedId) => {
            closeModal();
            setIsDataLoaded(() => {
              //getAddedComponents(false, recentlyImportedId);
              setActiveElementId(recentlyImportedId);
              return false;
            });
            //getAddedTextualElements();
          }}
          closeModalAndGoBack={closeImportModalAndGoBack}
          addedReusableTextIds={module.reusableTexts?.map((reusableText) => {
            return reusableText.reusableTextName;
          })}
          addedReusableTextData={module.reusableTexts
            ?.filter((component) => component.country === module.countryName)
            ?.map((reusableText) => {
              return {
                reusableTextId: reusableText?.reusableTextName,
                topic: reusableText?.topicName,
                subtopic: reusableText?.subtopicName,
                description: reusableText?.matchText,
                reusableTextType: reusableText?.textType,
              };
            })}
        />
      </CustomModal>
    </div>
  );
}

export default TextualElements;
