import ImportElements from "components/modules/ImportElements";
import ModuleBrandNavigation from "components/modules/ModuleBrandNavigation";
import ModuleHeader from "components/modules/ModuleHeader";
import ModuleSteps from "components/modules/ModuleSteps";
import Components from "components/modules/components/Components";
import ClaimsScreen from "components/modules/TextualElements";
import ReusableTexts from "components/modules/TextualElements";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { ModuleContext, moduleActionTypes } from "contexts/ModuleContext";
import MetaDataInformation from "components/modules/metadata/MetaDataInformation";

import {
  fetchModuleDetails,
  fetchTextualElements,
  fetchWithPromiseTextualElementsTypes,
  removeElementFromModule,
  fetchMetaDataDetails,
} from "services/apis";
import { useTranslation } from "react-i18next";
import { routes, showError } from "Uitls/Util";
import CircularProgressBar from "Uitls/CircularProgressBar";
import CustomModal from "components/customModal";
import ConfirmationPopup from "components/ConfirmationPopup";
import {
  getElementName,
  getElementNameKey,
  getElementsType,
  getRemoveEventName,
  moduleScreens,
} from "pages/module/ModuleUtil";
import ModulePreview from "components/modules/ModulePreview";
import ImportComponents from "components/modules/importComponents/ImportComponents";
import ImportClaims from "components/modules/importclaims/ImportClaims";
import ImportReusableText from "components/modules/importReusableTexts/ImportResusableText";

function Page(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [importClaimsModalOpen, setImportClaimsModalOpen] = useState(false);
  const [importReusableTextsModalOpen, setImportReusableTextsModalOpen] =
    useState(false);
  const [importComponentsModalOpen, setImportComponentsModalOpen] =
    useState(false);
  const [modulePreviewData, setModulePreviewData] = useState([]);
  const [createModuleClicked, toggleCreateModuleClicked] = useState(false);
  const [metaDataDropDownList, setMetaDataDropDownList] = useState(undefined);
  const {
    module,
    activeScreen,
    updateModule,
    isloading,
    setIsloading,
    elementToBeRemoved,
    setElementToBeRemoved,
    activeElementType,
    setActiveElementType,
    setCurrentElementActiveIndex,
    setActiveElementId,
    setActiveScreen,
    setMessageTypes,
    setTextTypes,
  } = useContext(ModuleContext);

  useEffect(() => {
    if (location && location.state) {
      if (location.state.defaultScreen) {
        setActiveScreen(location.state.defaultScreen);
        setModulePreviewData(location.state.modulePreviewData);
        return;
      }
      sessionStorage.setItem("moduleId", location.state.moduleId);
      updateModule(moduleActionTypes.SET_MODULE, location.state);
      getMetaDataAndModuleDetails();
    } else {
      getMetaDataAndModuleDetails();
    }
    setActiveScreen(moduleScreens.home);
  }, [location]);

  const getMetaDataAndModuleDetails = (firstload) => {
    setIsloading(true);
    Promise.all([
      fetchWithPromiseTextualElementsTypes("messageType", "claims"),
      fetchWithPromiseTextualElementsTypes("reusableTextType", "reusableTexts"),
    ])
      .then(([msgTypes, txtTypes]) => {
        setMessageTypes(msgTypes.data);
        setTextTypes(txtTypes.data);
        if (!firstload) {
          getModuleDetails();
        } else {
          setIsloading(false);
        }
      })
      .catch((err) => {
        showError(err);
        if (!firstload) {
          getModuleDetails();
        } else {
          setIsloading(false);
        }
      });
  };

  const getModuleDetails = () => {
    const moduleId = sessionStorage.getItem("moduleId");
    if (!moduleId) {
      history.replace(routes.dashboard);
      return;
    }
    setIsloading(true);
    fetchModuleDetails(moduleId, (res, err) => {
      setIsloading(false);
      if (res) {
        try {
          updateModule(moduleActionTypes.SET_MODULE, res.data);
        } catch (e) {
          console.log(e);
        }
        return;
      }
      showError(err);
    });
  };

  const removeElement = () => {
    removeElementFromModule(
      module.moduleId,
      getElementsType(activeElementType),
      elementToBeRemoved,
      (res, err) => {
        if (res) {
          updateModule(getRemoveEventName(activeElementType), {
            id: elementToBeRemoved,
          });
          setCurrentElementActiveIndex((prevIndex) => {
            setActiveElementId(undefined);
            if (prevIndex === 0) {
              if (module[getElementsType(activeScreen)]?.length === 1) {
                setActiveScreen(moduleScreens.home);
                prevIndex--;
              }
            } else if (
              prevIndex ===
              module[getElementsType(activeScreen)]?.length - 1
            ) {
              prevIndex--;
            }

            return prevIndex;
          });

          handleRemoveModal();
          return;
        }
        showError(err);
      }
    );
  };

  const handleRemoveModal = () => {
    setElementToBeRemoved(undefined);
    setActiveElementType(undefined);
    //setActiveElementId(undefined);
  };

  const getModuleDetailsForPreview = () => {
    const moduleId = sessionStorage.getItem("moduleId");
    if (!moduleId) {
      return;
    }
    setIsloading(true);
    fetchModuleDetails(moduleId, (res, err) => {
      if (res) {
        setIsloading(false);
        setModulePreviewData(res.data);
        setActiveScreen(moduleScreens.modulePreview);
      } else {
        setIsloading(false);
        showError(err);
      }
    });
  };

  const getDropDownDataForMetaDataScreen = () => {
    setIsloading(true);
    fetchMetaDataDetails((res, err) => {
      setIsloading(false);
      if (res) {
        try {
          setMetaDataDropDownList(res.data);
          toggleCreateModuleClicked(true);
        } catch (e) {
          console.log(e);
        }
        return;
      }
      showError(err);
    });
  };
  return (
    <>
      <CircularProgressBar isloading={isloading} />
      <ModuleHeader />
      <ModuleSteps activeScreen={activeScreen} />
      {activeScreen !== moduleScreens.modulePreview ? (
        <ModuleBrandNavigation
          showPromoMatsID={true}
          handleEditMetaData={() => {
            getDropDownDataForMetaDataScreen(true);
          }}
        />
      ) : null}
      {activeScreen === moduleScreens.home && (
        <ImportElements
          onImportClick={(elementType) => {
            switch (elementType) {
              case moduleScreens.claims:
                setImportClaimsModalOpen(true);
                break;
              case moduleScreens.reusableTexts:
                setImportReusableTextsModalOpen(true);
                break;
              case moduleScreens.components:
                setImportComponentsModalOpen(true);
                break;

              default:
                break;
            }
          }}
          getModuleDetailsForPreview={getModuleDetailsForPreview}
          handleCancel={() => {
            history.push({
              pathname: routes.dashboard,
              state: {
                selectedTab: 0,
              },
            });
          }}
        />
      )}
      {activeScreen === moduleScreens.claims && (
        <ClaimsScreen
          elementLabel="Claim"
          elementType={t("module.claims").toLowerCase()}
        />
      )}
      {activeScreen === moduleScreens.reusableTexts && (
        <ReusableTexts
          elementLabel="Reusable Text"
          elementType="reusableTexts"
        />
      )}
      {activeScreen === moduleScreens.components && (
        <Components
          onImportClick={() => {
            setImportComponentsModalOpen(true);
          }}
        />
      )}
      {activeScreen === moduleScreens.modulePreview && (
        <ModulePreview
          setActiveScreen={setActiveScreen}
          moduleScreens={moduleScreens}
          modulePreviewData={modulePreviewData}
          setModulePreviewData={setModulePreviewData}
        />
      )}
      <CustomModal
        displayModal={elementToBeRemoved}
        hideModal={() => {
          handleRemoveModal();
        }}
        size="sm"
        dilogClassName="alertModalSmall"
      >
        <ConfirmationPopup
          title={t("generalTexts.removingElementTitle", {
            elementType: getElementName(activeElementType),
          })}
          text={t("generalTexts.removingElementMessage", {
            elementType: getElementName(activeElementType),
          })}
          closeModal={handleRemoveModal}
          handleConfirm={removeElement}
        />
      </CustomModal>
      <CustomModal
        displayModal={importClaimsModalOpen}
        hideModal={() => {
          setImportClaimsModalOpen(false);
          //closeImportModalAndGoBack();
        }}
        size="xl"
        dilogClassName="alertModalSmall"
      >
        <ImportClaims
          closeModalAndRefresh={(recentlyImportedId) => {
            setImportClaimsModalOpen(false);
            if (activeScreen !== moduleScreens.claims) {
              setActiveElementId(() => {
                setActiveScreen(moduleScreens.claims);
                return recentlyImportedId;
              });
            }
          }}
          closeModalAndGoBack={() => {
            setImportClaimsModalOpen(false);
          }}
          addedClaimIds={module.claims?.map((component) => {
            return component.claimName;
          })}
          addedClaimsData={module.claims
            ?.filter((component) => component.country === module.countryName)
            ?.map((component) => {
              return {
                claimId: component?.claimName,
                topic: component?.topicName,
                subtopic: component?.subtopicName,
                description: component?.matchText,
                messageType: component?.messageType,
              };
            })}
        />
      </CustomModal>

      <CustomModal
        displayModal={importReusableTextsModalOpen}
        hideModal={() => {
          setImportReusableTextsModalOpen(false);
          //closeImportModalAndGoBack();
        }}
        size="xl"
        dilogClassName="alertModalSmall"
      >
        <ImportReusableText
          closeModalAndRefresh={(recentlyImportedId) => {
            setImportReusableTextsModalOpen(false);
            if (activeScreen !== moduleScreens.reusableTexts) {
              setActiveElementId(() => {
                setActiveScreen(moduleScreens.reusableTexts);
                return recentlyImportedId;
              });
            }
          }}
          closeModalAndGoBack={() => {
            setImportReusableTextsModalOpen(false);
          }}
          addedReusableTextIds={module.reusableTexts?.map((component) => {
            return component[getElementNameKey(moduleScreens.reusableTexts)];
          })}
          addedReusableTextData={module.reusableTexts
            ?.filter((component) => component.country === module.countryName)
            ?.map((component) => {
              return {
                reusableTextId: component?.reusableTextName,
                topic: component?.topicName,
                subtopic: component?.subtopicName,
                description: component?.matchText,
                reusableTextType: component?.textType,
              };
            })}
        />
      </CustomModal>

      <CustomModal
        displayModal={importComponentsModalOpen}
        hideModal={() => {
          setImportComponentsModalOpen(false);
          //closeImportModalAndGoBack();
        }}
        size="xl"
        dilogClassName="alertModalSmall"
      >
        <ImportComponents
          closeModalAndRefresh={(recentlyImportedId) => {
            setImportComponentsModalOpen(false);
            if (activeScreen !== moduleScreens.components) {
              setActiveElementId(() => {
                setActiveScreen(moduleScreens.components);
                return recentlyImportedId;
              });
            }
          }}
          closeModal={() => {
            setImportComponentsModalOpen(false);
          }}
          addedComponentIds={module.components?.map((component) => {
            return component.componentDocNumber;
          })}
        />
      </CustomModal>
      <CustomModal
        displayModal={createModuleClicked}
        hideModal={() => {
          toggleCreateModuleClicked(false);
        }}
        size="lg"
        dilogClassName="alertModalSmall"
      >
        <MetaDataInformation
          closeMetaDataScreen={() => {
            toggleCreateModuleClicked(false);
          }}
          gotoNextPage={() => {
            toggleCreateModuleClicked(false);
            getMetaDataAndModuleDetails();
          }}
          editMetaDataFlag={createModuleClicked}
          metaDataDropDownList={metaDataDropDownList}
          buttonLable="Update module"
          module={module}
        />
      </CustomModal>
    </>
  );
}

export default Page;
