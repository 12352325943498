import React, { useEffect, useMemo, useState } from "react";

import LogoutCTA from "pages/LogoutCTA";

import "styles/pages/Dashboard.scss";
import ModulesDashboard from "components/dashboard/ModuleDashbaord/ModulesDashboard";
import DashboardHeader from "dashboard/DashboardHeader";
import DashboardFilter from "dashboard/DashboardFilter";
import DashboardBlueTile from "dashboard/DashboardBlueTile";
import CustomModal from "components/customModal";
import MetaDataInformation from "components/modules/metadata/MetaDataInformation";
import {
  clearSessionAndLocalStorage,
  routes,
  showError,
  metaDataTypes,
} from "Uitls/Util";
import { useHistory } from "react-router-dom";
import { fetchMetaDataDetails, fetchModuleCounts } from "services/apis";
import CircularProgressBar from "Uitls/CircularProgressBar";
import LoadingBar from "react-top-loading-bar";

function Dashboard() {
  const history = useHistory();
  const [filtersData, setFiltersData] = useState({ createdByMe: true });
  const [selectedTab, setSelectedTab] = useState(
    history?.location?.state?.selectedTab || 0
  );
  const [createModuleClicked, toggleCreateModuleClicked] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [metaDataDropDownList, setMetaDataDropDownList] = useState(undefined);
  const [totalElements, setTotalElements] = useState(null);
  const [moduleCountSummary, setModuleCountSummary] = useState(undefined);
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  useEffect(() => {
    //localStorage.removeItem("bsrulesPageData");
    getDropDownDataForMetaDataScreen();
    clearSessionAndLocalStorage();
    getModuleCounts();
  }, []);

  const getModuleCounts = () => {
    fetchModuleCounts((res, err, errCode) => {
      if (res) {
        setModuleCountSummary(res.data);
        return;
      }
    });
  };

  useEffect(() => {
    setFiltersData({ createdByMe: true });
  }, [selectedTab]);

  const updateFiltersData = (updatedFiltersData) => {
    setFiltersData((prevState) => {
      return updatedFiltersData;
    });
  };

  const header = useMemo(() => {
    return <DashboardHeader />;
  }, []);
  const blueTile = useMemo(() => {
    return (
      <DashboardBlueTile
        handleTabSelection={(newTab) => {
          setSelectedTab(newTab);
        }}
        selectedTab={selectedTab}
      />
    );
  }, [selectedTab]);
  const filter = useMemo(() => {
    return (
      <DashboardFilter
        selectedTab={selectedTab}
        applyFilters={updateFiltersData}
        handleCreateModule={() => {
          toggleCreateModuleClicked(true);
          //getDropDownDataForMetaDataScreen();
        }}
        filtersData={filtersData}
        setFiltersData={setFiltersData}
        metaDataDropDownList={metaDataDropDownList}
        totalElements={totalElements}
        moduleCountSummary={moduleCountSummary}
        isFiltersApplied={isFiltersApplied}
      />
    );
  }, [
    selectedTab,
    filtersData,
    metaDataDropDownList,
    totalElements,
    moduleCountSummary,
    isFiltersApplied,
  ]);
  const deleteFilterOption = (item) => {
    let data = [];
    let result = [];
    let newFilterData = { ...filtersData };
    switch (item.metaDataType) {
      case metaDataTypes.product:
        data = [...filtersData["primary product"]];
        result = removeFilterOptionFromFilterData(data, item);
        newFilterData["primary product"] = result;
        newFilterData["sub-topic"] = [];
        break;
      case metaDataTypes.country:
        data = [...filtersData["primary country"]];
        result = removeFilterOptionFromFilterData(data, item);
        newFilterData["primary country"] = result;
        break;
      case metaDataTypes.topic:
        data = [...filtersData?.topic];
        result = removeFilterOptionFromFilterData(data, item);
        newFilterData.topic = result;
        newFilterData["sub-topic"] = [];
        break;
      case metaDataTypes.subTopic:
        data = [...filtersData["sub-topic"]];
        result = removeFilterOptionFromFilterData(data, item);
        newFilterData["sub-topic"] = result;
        break;
      case "searchText":
        newFilterData.searchText = undefined;
        break;
      case metaDataTypes.language:
        data = [...filtersData?.language];
        result = removeFilterOptionFromFilterData(data, item);
        newFilterData.language = result;
        break;
      case metaDataTypes.segment:
        data = [...filtersData?.segment];
        result = removeFilterOptionFromFilterData(data, item);
        newFilterData.segment = result;
        break;
      case metaDataTypes.indication:
        data = [...filtersData?.indication];
        result = removeFilterOptionFromFilterData(data, item);
        newFilterData.indication = result;
        break;
      case metaDataTypes.theraupaticArea:
        data = [...filtersData["therapeutic area"]];
        result = removeFilterOptionFromFilterData(data, item);
        newFilterData["therapeutic area"] = result;
        break;
      case metaDataTypes.strategicobj:
        data = [...filtersData["strategic objective"]];
        result = removeFilterOptionFromFilterData(data, item);
        newFilterData["strategic objective"] = result;
        break;
      case metaDataTypes.mlr:
        data = [...filtersData["mlr status"]];
        result = removeFilterOptionFromFilterData(data, item);
        newFilterData["mlr status"] = result;
        break;
      default:
        break;
    }
    setFiltersData(newFilterData);
  };

  const removeFilterOptionFromFilterData = (data, item) => {
    if (data.length > 0) {
      return data.filter((element) => {
        return element.veevaId !== item.veevaId;
      });
    }
    return [];
  };

  const resetAll = () => {
    setFiltersData({ createdByMe: filtersData.createdByMe });
  };

  const checkIsFiltersApplied = (count) => {
    if (count > 0) {
      setIsFiltersApplied(true);
    } else {
      setIsFiltersApplied(false);
    }
  };

  const modulesDashboard = useMemo(() => {
    return (
      <ModulesDashboard
        setSelectedTab={setSelectedTab}
        handleCreateModule={() => {
          toggleCreateModuleClicked(true);
          //getDropDownDataForMetaDataScreen();
        }}
        selectedTab={selectedTab}
        filtersData={filtersData}
        deleteFilterOption={deleteFilterOption}
        resetAll={resetAll}
        setTotalElements={setTotalElements}
        isFiltersApplied={checkIsFiltersApplied}
        getModuleCounts={getModuleCounts}
      />
    );
  }, [selectedTab, filtersData]);

  const goToAssembleModulePage = (module) => {
    history.push({
      pathname: routes.module,
      state: module,
    });
  };
  const getDropDownDataForMetaDataScreen = () => {
    setIsloading(true);
    fetchMetaDataDetails((res, err) => {
      setIsloading(false);
      if (res) {
        try {
          setMetaDataDropDownList(res.data);
          // toggleCreateModuleClicked(true);
        } catch (e) {
          console.log(e);
        }
        return;
      }
      showError(err);
    });
  };

  return (
    <>
      {/* <CircularProgressBar isloading={isloading} /> */}
      <LoadingBar isloading={isloading} />
      {header}
      {blueTile}
      {filter}
      {modulesDashboard}

      <CustomModal
        displayModal={createModuleClicked}
        hideModal={() => {
          toggleCreateModuleClicked(false);
        }}
        size="lg"
        dilogClassName="alertModalSmall"
      >
        <MetaDataInformation
          closeMetaDataScreen={() => {
            toggleCreateModuleClicked(false);
          }}
          gotoNextPage={goToAssembleModulePage}
          metaDataDropDownList={metaDataDropDownList}
          buttonLable="Create module"
        />
      </CustomModal>
    </>
  );
}

export default Dashboard;
